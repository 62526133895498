import React from 'react'
import emailjs from '@emailjs/browser';
import {useRef, useEffect, useState} from 'react';
import { supabase } from '../../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import './WaitingOnPeople.css'
const WaitingOnPeople = (props) => {
  const remainingPeople = props.remainingPeople.remainingPeople
  const myName = props.remainingPeople.name
  const teamname = props.remainingPeople.teamname
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const REACT_APP_SERVICEID = process.env.REACT_APP_WAITING_SERVICEID;
  const REACT_TEMPLATEID= process.env.REACT_APP_WAITING_TEMPLATEID;
  const REACT_PUBLICKEY = process.env.REACT_APP_WAITING_USERID;
const form = useRef();
  const sendEmail=(e)=>{
    e.preventDefault();
    alert("submitted");
    emailjs.sendForm(REACT_APP_SERVICEID, REACT_TEMPLATEID,form.current, REACT_PUBLICKEY);
  }
  useEffect(() => {
    fetchAlreadyRegistered();
  }, [])

  const fetchAlreadyRegistered = async() =>{
    try{
      const{data, error} = await supabase.from("profiles").select().eq('team_name', teamname);
      if(error) {
        alert('Something went wrong')
        console.log(error);}
      if(data!=null){
        let names = []
        data.map((item, index) => {
         names.push(item.full_name);
      })
         setRegisteredMembers(names);
      }
  }
  catch(error){
      alert(error.message);
  }
  }
  return (
    <div className = "waitingOnPeople">
      <h1>Thanks for registering!</h1>
      {/* Display cards for registered members */}
      <div className="registered-members-container">
        {registeredMembers.map((member, index) => (
          <div key={index} className="registered-member">
            {member+' '}
            {<FontAwesomeIcon icon={faSquareCheck} style={{color: "#669c35",}} />}
          </div>
        ))}
      </div>
      
      <p> All of the above people have registered for team {teamname} and you are still waiting on {remainingPeople} member(s) to register their availabilities.</p>
      <p> Send them a reminder and let them know what team they should register for.</p>
       

      <form className="contact__form" ref = {form} onSubmit = {sendEmail}>
      <label htmlFor="email_to">From:</label>
      <input type="text" name="from_name" id="from_name" className="from_name" value={myName} readOnly/>
        <label htmlFor="email_to">Their Email:</label>
        <input type="text" name="email_to" id="email_to" className="email_to" placeholder="person@example.com"/>
        <label htmlFor="name_to">Name:</label>
        <input type="text" name="to_name" id="to_name" className="to_name" placeholder="Their Name"/>
        <label htmlFor="message">Message:</label>
        <textarea name="message" id="message" className="message__box"placeholder='Hey, this is a reminder to register your availability so that we can make a schedule for tenting!'></textarea>
        <button >Submit</button>
      </form>
    </div>
  )
}

export default WaitingOnPeople
