import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { supabase } from '../supabaseClient.js'
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React,{useState, useEffect,useCallback} from 'react';
import { EmailForm } from "../components/EmailForm/EmailForm.js";
import './MySchedule.css';
import TradeBar from "../components/TradeBar/TradeBar";
import MissingMemberTrade from "../components/MissingMemberTrade/MissingMemberTrade.js";
import WeekInput from "../WeekInput/WeekInput.js";

const localizer = momentLocalizer(moment);

 function MySchedule (props){
  const myName = props.props.name;
  const teamName = props.props.teamname;
  const email = props.props.email;
//for events
  let names = [];
  let groupScheduleColors = [];
  const[myGroup, setMyGroup] = useState(null)
  const[uniqueNames, setUniqueNames] = useState([]);
  const[eventList, setEventList] = useState([]);
  const[groupView, setGroupView] = useState(false);
  const[myEventList, setMyEventList] = useState(null);
  const[combinedMyEventList, setCombinedMyEventList] = useState(null);
  const[missingEventList, setMissingEventList] = useState(null);
  const[missingShiftPick, setMissingShiftPick] = useState(null);
  const [open, setOpen] = useState(false);

  //event clicked on for trade
  const[selectEvent, setSelectEvent] = useState(false);
  const tradeshift = {selectEvent,email}
  useEffect(() => {
    fetchMySchedule();
    fetchGroupSchedule();

// setEventList(events);
  }, []);

async function fetchMySchedule(){
  let myEvents = []
  let MissingEvents = []
  //gets Black blue etc
  try{
    const { data, error } = await supabase
    .from('schedules')
    .select()
    .eq('name', myName)
    .eq('team_name',teamName)
    if(error) {  alert('Something went wrong')
    console.log(error)}
    else{
     data.forEach(event => {
      const color = event.color;
        event.startTimes.forEach((startTime, idx) =>{
          const endTime = event.endTimes[idx];
            myEvents.push({
              start: new Date(startTime),
              end: new Date(endTime),
              title: color,
              currentid:myName,
            })
        });
      });
      setMyEventList(myEvents);
    }
}

catch(error){
    alert(error.message);
}

//need to also getting missing persons
try{
  const { data, error } = await supabase
  .from('schedules')
  .select()
  .eq('name', 'Missing Member')
  .eq('team_name',teamName)
  if(error){
    alert('Something went wrong')
    console.log(error)
  }
  else{
   data.forEach(event => {
    const color = event.color;
    if(event.startTimes.length !=0){
      event.startTimes.forEach((startTime, idx) =>{
        const endTime = event.endTimes[idx];
          MissingEvents.push({
            start: new Date(startTime),
            end: new Date(endTime),
            title: color,
            currentid:'Missing Member',
          })
      });
    }
    });
    setMissingEventList(MissingEvents);
    const combinedMyEvents= [...MissingEvents, ...myEvents];
setCombinedMyEventList(combinedMyEvents);
  }
}

catch(error){
  console.log(error);
  alert(error.message);
}

}

//fetch Everyone's schedule
async function fetchGroupSchedule() {
  let events = []
  //gets Black blue etc
  try {
    const { data, error } = await supabase
      .from('schedules')
      .select()
      .eq('team_name', teamName)
    if (error) {
      alert('Something went wrong')
      console.log(error)
    }
    else {
      data.forEach(event => {
        event.startTimes.forEach((startTime, idx) => {
          const endTime = event.endTimes[idx];
          const startDate = new Date(startTime)
          const endDate = new Date(endTime)

          if (startDate.getDay() == endDate.getDay()) {
            events.push({
              start: new Date(startTime),
              end: new Date(endTime),
              title: event.color,
              currentid: event.name,
            })
          }
          else {
            const day1placeholder = new Date(startTime)
            day1placeholder.setHours(23, 59, 0, 0)
            events.push({
              start: new Date(startTime),
              end: day1placeholder,
              title: event.color,
              currentid: event.name,
            })

            const day2placeholder = new Date(endTime)
            day2placeholder.setHours(0, 0, 0, 0)
            const endDate = new Date(endTime)
            if (endDate.getHours() != 0 || endDate.getMinutes() != 0) {
              events.push({
                start: day2placeholder,
                end: endDate,
                title: event.color,
                currentid: event.name,
              })
            }
          }
          names.push(event.name);
        });
      });
      //create a set for unique names and set to array
      setUniqueNames([...new Set(names.map(obj => obj))]);
      setEventList(events);
    }
  }

  catch (error) {
    alert(error.message);
  }
}

  // // Reversing the process and creating Date objects
  // const startDate = new Date(`${event.startdate}T${event.starttime}Z`);
  // const endDate = new Date(`${event.enddate}T${event.endtime}Z`);

  
  // Generate a random color for each name
  function generateRandomColor() {
    // Generate random pastel colors
    const hue = Math.floor(Math.random() * 360);
    const saturation = Math.floor(Math.random() * 30)+10; // Adjust saturation for pastel colors
    const lightness = Math.floor(Math.random() * 20) + 70; // Adjust lightness for pastel colors
  
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }
  
//function to add events to your google calendar
const addCalendarEvents = () => {
  var gapi = window.gapi
  var CLIENT_ID = '326309121506-h8uhea8b7vdorkm4sr466thuptqd18e7.apps.googleusercontent.com';
  var API_KEY = 'AIzaSyBa_U_SEN6DA0n7kahUXr_QWpaoEUdWOko';
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar"
 gapi.load('client:auth2', () => {
            console.log('loaded client')
      
            gapi.client.init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
            })
      
            gapi.client.load('calendar', 'v3', () => console.log('bam!'))
      
            gapi.auth2.getAuthInstance().signIn()
            .then(() => {
              
              var event = {
                'summary': 'Awesome Event!',
                'location': '800 Howard St., San Francisco, CA 94103',
                'description': 'Really great refreshments',
                'start': {
                  'dateTime': '2020-06-28T09:00:00-07:00',
                  'timeZone': 'America/Los_Angeles'
                },
                'end': {
                  'dateTime': '2020-06-28T17:00:00-07:00',
                  'timeZone': 'America/Los_Angeles'
                },
                'recurrence': [
                  'RRULE:FREQ=DAILY;COUNT=2'
                ],
                'attendees': [
                  {'email': 'lpage@example.com'},
                  {'email': 'sbrin@example.com'}
                ],
                'reminders': {
                  'useDefault': false,
                  'overrides': [
                    {'method': 'email', 'minutes': 24 * 60},
                    {'method': 'popup', 'minutes': 10}
                  ]
                }
              }
      
              var request = gapi.client.calendar.events.insert({
                'calendarId': 'primary',
                'resource': event,
              })
      
              request.execute(event => {
                console.log(event)
                window.open(event.htmlLink)
              })
            })
          })
}




//function to color the events according to person
const components ={
  event: (props) =>{
    const eventType = props?.event?.title
    
  }
}

//function for when you click event
const onSelectEvent = useCallback((calEvent) => {
  setOpen(true);
setSelectEvent({event: calEvent,
  teamname:teamName});
  if(calEvent.currentid === 'Missing Member'){
    setMissingShiftPick(true);
  }
}, [])

//function for when you click group event
//function for when you click event
const onSelectGroupEvent = useCallback((calEvent) => {
 alert(calEvent.currentid+ ' has '+ calEvent.start.toString().substring(0,21)+ ' to '+ calEvent.end.toString().substring(0,21));
}, [])

//function for styling
const eventPropGetter = (event, start, end, isSelected) => {
  let style;
  if(event.currentid === 'Missing Member'){
    style = {
      backgroundColor: 'lightcoral',
      border: '2px solid #3174ad',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      display: 'block',
      padding: '5px',
      marginLeft: '5px', // Adjust the left margin to create space between events
    };
  }
  else{
  style = {
    backgroundColor: 'lightblue',
    border: '2px solid #3174ad',
    borderRadius: '5px',
    opacity: 0.8,
    color: 'black',
    display: 'block',
    padding: '5px',
    marginLeft: '5px', // Adjust the left margin to create space between events
  };
  }
  return {style};
};

//function for styling group events
const groupeventPropGetter = (event, start, end, isSelected) => {
  let withoutExcess = (uniqueNames.filter(name => name !== 'Grace Period'&& name!== 'Missing Member'));
// let colors =[
//     "#ff69b4",
//     "#8b008b",
//    "#483d8b",
//     "#4b0082",
//     "#1e90ff",
//     "#0000ff",
//     "#00008b",
//     "#41534A",
//     "#006400",
//     "#ffff00",
//     "#ff8c00",
//    "#ff0000"
// ]
let colors = [
  "#0C2D48",
  "#ff7f0e",
  "#FCF55F",
  "#9467bd",
  "#8c564b",
  "#167d7f",
  "#7f7f7f",
  "#8a3e85",
  "#17becf",
  "#ff33cc",
  "#023020",
  "#3c005a",
];
  let style;
  if(event.currentid === 'Grace Period'){
    style = {
      backgroundColor: 'lightgreen',
      border: '2px solid #3174ad',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      display: 'block',
      padding: '5px',
      marginLeft: '5px', // Adjust the left margin to create space between events
    };
    if(!isNameInArray('Grace Period', groupScheduleColors)){
      groupScheduleColors.push({
        name:'Grace Period',
        color: 'lightgreen'
       })
      }
  }
  else if(event.currentid === 'Missing Member'){
    style = {
      backgroundColor: 'red',
      border: '2px solid #3174ad',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      display: 'block',
      padding: '5px',
      marginLeft: '5px', // Adjust the left margin to create space between events
    };
  }
  else {
  withoutExcess.forEach((name, index) => {
    if (event.currentid === name) {
      style = {
        backgroundColor: colors[index],
        border: '2px solid #3174ad',
        borderRadius: '5px',
        opacity: 0.8,
        color: 'black',
        display: 'block',
        padding: '5px',
        marginLeft: '5px', // Adjust the left margin to create space between events
      };
      if(!isNameInArray(name, groupScheduleColors)&& groupScheduleColors.length<withoutExcess.length){
      groupScheduleColors.push({
        name: name,
        color: colors[index]
       })
      }


    }
  });
  if (withoutExcess.length === groupScheduleColors.length&& !myGroup ){
    groupScheduleColors.push(
      {name : 'Missing Member',
      color: 'red'
    }
    )
    setMyGroup(groupScheduleColors);
  }
}

  return {style};
};

// Helper function to check if name already exists in the array
const isNameInArray = (name, array) => {
  return array.some(item => item.name === name);
};
//function for handling button click
function handleGroup(){
  setGroupView(true);
}
function handleMy(){
  setGroupView(false);
}
function handleTradeExit(){
setOpen(false);
}

 {
    return (<div className = "switchSchedule">
      {!groupView? <button className = "switchButton" onClick = {handleGroup}>See Team Schedule</button>: 
    <button className = "switchButton" onClick = {handleMy}>See My Schedule</button>
    }
    {!groupView? <> 
     {/* my event view */}

      {/* if open is true, show trade screens: otherwise show my schedule */}
      {open? <> {missingShiftPick? //if they picked shift for Missing Member, then show specific screen for that
       <div><button className = "exitTrade" onClick ={handleTradeExit}> Exit Trade </button>
       <MissingMemberTrade shift = {{event:selectEvent, name:myName, team:teamName}}/> </div>
     :
      //otherwise show generic trading screen
      <>
      <div className = "topOfTrade"><button className = "exitTrade" onClick ={handleTradeExit}> Exit Trade </button> <div className = "email" style = {{height: "70vh"}}>
       <EmailForm tradeshift = {{selectEvent,email}}/></div>
       
        </div></>}</>
        
        : <div className = "Main">
          <div className = "key">
          <h1>My Schedule</h1>
         
        <div className = "moreinfo">You can click on a shift in the week view to trade it with another member. To view multi-day shifts, use the "Agenda" tab.</div>
        </div>
        <div className ="spacer"> </div>
        <div style = {{height: "80vh"}}>
        {combinedMyEventList&& <Calendar
          defaultDate={moment('2024-01-21').toDate()}
          defaultView="week" //default view is the week view
          events={combinedMyEventList} //only my events and any missing
          allDayMaxRows = {1}
          eventPropGetter={eventPropGetter}
          components = {components}
          localizer={localizer}
          onSelectEvent={onSelectEvent}
          style={{ height: "70vh" }}
        /> }
       
      </div>
      </div>
      }

      </>
      : 
      <> 
      <div className = "Main" >
      <h1>Team Schedule</h1>
      <div className="key">
  {myGroup &&
    myGroup.map((colorInfo, index) => (
      <div key={index} className="keyItem">
        <div className="colorBox" style={{ backgroundColor: colorInfo.color }}></div>
        <div className="colorName">{colorInfo.name}</div>
      </div>
    ))}
</div>

       <div style = {{height: "80vh"}}>
        <Calendar
          defaultDate={moment('2024-01-21').toDate()}
          defaultView="week" //default view is the week view
          events={eventList} 
          dayLayoutAlgorithm={'no-overlap'}
          allDayMaxRows = {1}
          eventPropGetter={groupeventPropGetter}
          components = {components}
          localizer={localizer}
          onSelectEvent={onSelectGroupEvent}
          style={{ height: "70vh" }}
          popup
        />
      </div>
      </div>
      </>
      }
      <div className = "trade">
        {<TradeBar name = {myName}/>}
      </div>
</div>
    );
  }
}

export default MySchedule;