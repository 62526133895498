import {useState,useEffect, useRef} from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import './WeekInput.css';
import React from 'react';
import ApiCalendar from 'react-google-calendar-api';
import moment from 'moment';
import { supabase } from '../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import FetchSchedules from '../components/FetchSchedules/FetchSchedules';
import debounce from 'lodash/debounce';
import  { useCallback, useMemo} from 'react'
import CustomWeekView from './CustomWeekView.js'
import events from './events.js'
import { Calendar, Views, DateLocalizer, momentLocalizer} from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function WeekInput(toWeek) {
  const[emptyArray, setEmptyArray ]= useState(false);

  const localizer = momentLocalizer(moment);

  const DragAndDropCalendar = withDragAndDrop(Calendar) 

const adjEvents = events.map((it, ind) => ({
  ...it,
  isDraggable: true,
  isResizable:true,
}))
const [myEvents, setMyEvents] = useState(adjEvents)
const[manual, setManual] = useState(null);
let keepTrackOfEvents = [];
const[loading, setLoading] = useState(true);
let startTimes  = [];
let endTimes = [];

const name = toWeek.toWeek.name ;
const id = toWeek.toWeek.id;
const email = toWeek.toWeek.email;
const teamname = toWeek.toWeek.teamname;

const {  formats } = useMemo(
  () => ({
    formats: {
      dayFormat: (date, culture, localizer) =>
        localizer.format(date, 'ddd', culture),
    }
  }),
  []
)

const clickRef = useRef(null)

useEffect(() => {
  /**
   * What Is This?
   * This is to prevent a memory leak, in the off chance that you
   * teardown your interface prior to the timed method being called.
   */
  return () => {
    window.clearTimeout(clickRef?.current)
  }
}, [])

const onSelectEvent = useCallback((calEvent) => {
  /**
   * Here we are waiting 250 milliseconds (use what you want) prior to firing
   * our method. Why? Because both 'click' and 'doubleClick'
   * would fire, in the event of a 'doubleClick'. By doing
   * this, the 'click' handler is overridden by the 'doubleClick'
   * action.
   */
  window.clearTimeout(clickRef?.current)
  clickRef.current = window.setTimeout(() => {
    alert('Deleting event')
   myEvents&& deleteEvent(calEvent);
  }, 500)
}, [])

const onCreateEvent = useCallback((calEvent) => {
  /**
   * Here we are waiting 250 milliseconds (use what you want) prior to firing
   * our method. Why? Because both 'click' and 'doubleClick'
   * would fire, in the event of a 'doubleClick'. By doing
   * this, the 'click' handler is overridden by the 'doubleClick'
   * action.
   */
 setTimeout(() => {
   myEvents&& newEvent(calEvent);
  //  keepTrackOfEvents=myEvents
  //  console.log(keepTrackOfEvents)
  }, 100)
}, [])


const deleteEvent =(event) =>{

  setMyEvents((prev) => {
    const filtered = prev.filter((ev) => ev.start != event.start && ev.end != event.end)
    return [...filtered]
  })
  
//   let newEvents =[];
// keepTrackOfEvents.map((element, index) => {
//     // Use index to uniquely identify each element
//     if (element.start === event.start) {
//       console.log('matches shift', element);
//     } 
//     else {
//      newEvents.push(element);
//     }
//   });
// setMyEvents(newEvents);
// keepTrackOfEvents=newEvents;
// console.log('after Delete', keepTrackOfEvents)
}


const moveEvent = useCallback(

  ({ event, start, end }) => {

  
    setMyEvents((prev) => {
      const filtered = prev.filter((ev) => ev.start != event.start || ev.end != event.end)
      return [...filtered, { start, end }]
    })
  },
  [setMyEvents]
)

const newEvent = useCallback(
  (event) => {
    setMyEvents((prev) => {
      return [...prev, { ...event }]
    })
  },
  [setMyEvents]
  // [setMyEvents]
)


const resizeEvent = useCallback(
  ({ event, start, end }) => {
    setMyEvents((prev) => {
      const filtered = prev.filter((ev) => ev.start != event.start && ev.end != event.end)
      return [...filtered, { start, end }]
    })
  },
  [setMyEvents]
)

const defaultDate = useMemo(() => new Date(2024, 0, 21), [])
//new stuff above

const {views} = useMemo(() => ({
  views: {
    month: false,
    week: CustomWeekView,
    day: false
  },
  // ... other props
}), [])


  const[submitWeek, setSubmitWeek] = useState(false);
  const [value, setValue] = useState(dayjs('Sun Jan 21 2024 00:00:00 GMT-0500 (Eastern Standard Time)'));
  const[selectedWeekStart, setSelectedWeekStart] = useState(null);
  //for the collection of availability
  const[busy, setBusy] = useState(null);
  let availabilities = [];

  //show myschedule tester
  const[showMySchedule, setShowMySchedule ] =useState(false);
  

  dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
};

//manually enter info
function handleManual(){
setManual(true);
}

const formatDate = (inputString) => {


  const date = new Date(inputString);
  return date;
};
function submitManual() {
  setLoading(true);

  myEvents.map((event, index) => {
    startTimes.push(new Date(event.start).toString().substring(0, 21));
    endTimes.push(new Date(event.end).toString().substring(0, 21));
    return null; // You need a return statement here, even if it's null
  });
  pushManual({startTimes:startTimes,
  endTimes: endTimes});

}

async function pushManual(toPush){
  try{

      
    const{error} = await supabase
    .from('Availability')
    .insert({  id: name,
          startTimes: toPush.startTimes,
          endTimes: toPush.endTimes,
          team_name:teamname,
         })
         if(error){
    if(error.code === "23505"){
      alert('Availability already exists for this name!')
      setShowMySchedule(false);
    }
    console.log(error);
  }
  else{
    setShowMySchedule(true);
    setLoading(false);
  }
        }
  catch(error){
    alert(error);
  }
}


async function pushAvailable(availabilities){
  let startTimes = [];
  let endTimes = [];
  // saving as a timestamptz format
  for(const busyEvent of availabilities){
    startTimes.push(new Date(busyEvent.start).toString().substring(0,21));
    endTimes.push(new Date(busyEvent.end).toString().substring(0,21));
  }
    try{
      const{error} = await supabase
      .from('Availability')
      .insert({  id: name,
            startTimes: startTimes,
            endTimes: endTimes,
            team_name:teamname,
           })
           if(error){
      if(error.code === "23505"){
        alert('Availability already exists for this name!')
        setShowMySchedule(false);
      }
      console.log(error);
    }
    else{
      setShowMySchedule(true);
    }
          }
    catch(error){
      alert(error);
    }

}


function handleMoreInformation(){
  alert("When2Tent will use your events from your primary calendar from 12:00AM on 1/21 to 11:59PM on 1/27 as times when you are unavailable.")
}

function Day(props) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

const eventPropGetter = (event, start, end, isSelected) => {
  let style;
    style = {
      backgroundColor: 'red',
      border: '2px solid #3174ad',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      display: 'block',
      padding: '5px',
      marginLeft: '5px', // Adjust the left margin to create space between events
    };
  return {style};
};

const calendarComponent = useMemo(() => (
  <Stack spacing={1} direction="column">
     <button onClick={submitManual} className="submit"> Submit </button>
    <DragAndDropCalendar
      defaultDate={defaultDate}
      toolbar={false}
      defaultView={Views.WEEK}
      views={views}
      formats={formats}
      events={myEvents}
      localizer={localizer}
      onEventDrop={moveEvent}
      onEventResize={resizeEvent}
      onSelectSlot={onCreateEvent}
      onDoubleClickEvent={onSelectEvent}
      eventPropGetter={eventPropGetter}
      resizable
      selectable
    />
    <div></div>
   
  </Stack>
), [defaultDate, views, formats, myEvents, localizer, moveEvent, resizeEvent, onCreateEvent, onSelectEvent, eventPropGetter]);


  return (
    <div className = "availabilityinput">
      {showMySchedule? <>
        {loading?<>loading</>
        :
        <>
        {emptyArray?<>empty </>
      
      : 
      <>    <FetchSchedules forFetch = {{name: name, id:id, email:email, teamname:teamname}}/> </>}
        </>
        }
      </>
        :
        //show my schedule is false
        <>
      <div className = 'availabilityInput'>
      <div>
        <p className = 'instructions'> Please input when you are busy by clicking and dragging. Double click a slot to delete it.</p>
      </div>
        <div className = 'calendar' style = {{height: "80vh"}}>
        {calendarComponent}
    <div className = "spacing"></div>
      </div>
    </div>
</>
      }
      </div>
  )
}