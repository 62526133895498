import './MissingMemberTrade.css'
import React, { useRef, useState, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { supabase } from '../../supabaseClient';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
function MissingMemberTrade(shift) {
    const myName = shift.shift.name;
    const color = shift.shift.event.event.title;
    const team = shift.shift.team;
    //    this is for the value of the picker for the trade
       const [value, setValue] = useState(dayjs(new Date(shift.shift.event.event.start)));
       const[endValue, setEndValue] = useState(dayjs(new Date(shift.shift.event.event.end)));
       const startOfShift = new Date(shift.shift.event.event.start);
       const endOfShift = new Date(shift.shift.event.event.end);
       const[submitAddShift, setSubmitAddShift] = useState(null);


    function handlePickStart(props){
        const selectedStart = new Date(props);
      const illegal = selectedStart && endOfShift && dayjs(endOfShift).isBefore(dayjs(selectedStart), 'day')||dayjs(selectedStart).isBefore(dayjs(startOfShift))
      illegal?<>{alert('Pick a start time for your trade that is before the end of this shift or return to your schedule to pick a different shift. The default value has been set to the start of this shift.')}
      {setValue(dayjs(startOfShift))}</> 
      :
      <>
      {setValue(props)}
      </>
    }

    function handlePickEnd(props){
        const selectedEnd = new Date(props);
        const illegal = selectedEnd && startOfShift && dayjs(selectedEnd).isBefore(dayjs(startOfShift), 'day')||dayjs(endOfShift).isBefore(dayjs(selectedEnd));
        illegal?<>{alert('Pick a end time for your trade that is within the range of this shift or return to your schedule to pick a different shift. The default value has been set to the end of this shift.')}
        {setEndValue(dayjs(endOfShift))}</> 
        :
        <>
        {setEndValue(props)}
        </>
    }

    async function tradeInSchedule(select){
        let newtStartTimes = [];
        let newrStartTimes = [];
        let newtEndTimes = [];
        let newrEndTimes = [];
      //missing person
      const tradersendToSplit = {
        start:select.tstart,
        end:select.tend,
        startOfShift: select.tstartOfShift,
        endOfShift:select.tendOfShift
      }
      //pull array of starttimes/endtimes for the missing member
      try{
        const { data, error } = await supabase
        .from('schedules')
        .select('startTimes, endTimes')
        .eq('name', 'Missing Member')
        .eq('color',color)
        .eq('team_name', team)
        .single()
        if(error) {
          alert('Something went wrong')
          console.log(error)
        }
        else{
         data.startTimes.map((element, index) => {
          // Use index to uniquely identify each element
          if (element === new Date(select.tstartOfShift).toString().substring(0,21)) {
            console.log('match');
          } else {
            newtStartTimes.push(element);
          }
        });
    
        //same for endtimes
        data.endTimes.map((element, index) => {
          // Use index to uniquely identify each element
          if (element === new Date(select.tendOfShift).toString().substring(0,21)) {
            console.log('matches')
          } else {
            newtEndTimes.push(element);
          }
        });
    
        let existingShifts = []
        newtEndTimes.map((element, index) => {
          existingShifts.push(
            {
              start: newtStartTimes[index],
              end:element,
            }
          )
        });
        const splitTrader = splitTrade(tradersendToSplit);
    
     
    
        //combines trader's shifts before or after the selected trade slot and existing Shifts that are not the full-trade slot and receivers slot
       const combinedTimes =
        [...splitTrader,...existingShifts]
       const forTradePush = {
        times:combinedTimes,
        name:'Missing Member',
        color:color,
       }
       pushNewTimesTrader(forTradePush);
        }
    }
    catch(error){
      console.log(error)
        alert(error.message);
    }



      //repeated process for receiver
      try{
        const { data, error } = await supabase
        .from('schedules')
        .select('startTimes,endTimes')
        .eq('name', myName)
        .eq('color',color)
        .single()
        if(error){
          alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
          console.log(error);
        }
        else{
         data.startTimes.map((element, index) => {
            newrStartTimes.push(element);
        });
      
        //same deletion for endtimes
        data.endTimes.map((element, index) => {
            newrEndTimes.push(element);
        });
        let existingRShifts = []
        newrEndTimes.map((element, index) => {
          existingRShifts.push(
            {
              start: newrStartTimes[index],
              end:element,
            }
          )
        });
        const tradersTradeToGive =[{
          start: select.tstart,
          end: select.tend,
        }]
        //combines receiver's shifts and trade slot
       const combinedTimes2 = [...existingRShifts, ...tradersTradeToGive]
       pushNewTimesReceiver(combinedTimes2);
        }
      }
      catch(error){
      console.log(error)
        alert(error.message);
      }
      
      
      }
      
      async function pushNewTimesTrader(toPush){
        let traderStartTimes = [];
        let traderEndTimes = [];
        toPush.times.map((element, index) => {
          traderStartTimes.push(new Date(element.start).toString().substring(0,21));
          traderEndTimes.push(new Date(element.end).toString().substring(0,21));
        });
        console.log(traderStartTimes);
        console.log(traderEndTimes);
        try{
          const {  error } = await supabase
          .from('schedules')
          .update({startTimes:traderStartTimes, 
          endTimes: traderEndTimes}
          )
          .select()
          .eq('name', toPush.name)
          .eq('color', color)
          if(error) {
            alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
            console.log(error);
          }
          else{
                console.log('successfully updated')
          }
      }
      catch(error){
        console.log(error)
          alert(error.message);
      }
      
      }
      
      async function pushNewTimesReceiver(toPush){
        let traderStartTimes = [];
        let traderEndTimes = [];
        toPush.map((element, index) => {
          traderStartTimes.push(new Date(element.start).toString().substring(0,21));
          traderEndTimes.push(new Date(element.end).toString().substring(0,21));
        });
        try{
          const {  error } = await supabase
          .from('schedules')
          .update({startTimes:traderStartTimes, 
            endTimes: traderEndTimes}
            )
          .select()
          .eq('name', myName)
          .eq('color',color)
          if(error) {
            alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
            console.log(error);
          }
          else{
                console.log('successfully updated')
          }
      }
      catch(error){
        console.log(error)
          alert(error.message);
      }
      
      }
      
      //splits original shift into before and after the trade shift
      function splitTrade(props){
        let parts = [];
        if(props.start > props.startOfShift){
          parts.push({start: props.startOfShift, end:props.start})
        }
        if(props.end< props.endOfShift){
          parts.push({start: props.end, end:props.endOfShift})
        }
        return parts;
      
      }

    function confirmTakeTrade(){
            //approve trade as if I am the receiver and missing member is the trader
   tradeInSchedule({
    tstart: value,
    tend: endValue,
    trader:'Missing Member',
    tendOfShift:endOfShift,
    tstartOfShift:startOfShift,
  })
  setSubmitAddShift(true);
    }

  return (
    <>{startOfShift?<>
     <div className = "missingMemberTrade">
        {submitAddShift?
        <>
        <p> Thanks for taking on that shift for the team!</p>
        </>
        :
        //haven't submitted yet
        
        <><p>Volunteer for {startOfShift.toString().substring(0,21)} to {endOfShift.toString().substring(0,21)} where there are not enough members to meet K-ville requirements!</p>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <DemoItem label="Start of Shift">
              <DateTimePicker 
              required
              // disableIgnoringDatePartForTimeValidation
              defaultValue={dayjs(startOfShift)} 
              value={value}
              onChange={(newValue) => handlePickStart(newValue)}
              skipDisabled = {true}
              minutesStep= {30}
               />
            </DemoItem>
            
            <DemoItem label="End of Shift">
              <DateTimePicker 
              required
              disableIgnoringDatePartForTimeValidation
              defaultValue={dayjs(endOfShift)} 
              value={endValue}
              onChange={(newValue) => handlePickEnd(newValue)}
              skipDisabled = {true}
              minutesStep= {30}
               />
            </DemoItem>
            <button className = "confirmTakeTrade" onClick = {() =>confirmTakeTrade()}>I'll add this to my schedule </button>
            </DemoContainer>
        </LocalizationProvider></>}



    
    </div></>
    :
    <>Loading</>}
   </>
  )
}

export default MissingMemberTrade
