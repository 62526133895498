import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link, Routes, Route } from 'react-router-dom';
import './App.css';
import {supabase} from './supabaseClient.js';
import { useState, useEffect } from 'react'
import Auth from './Auth/Auth.jsx'
import Account from './Account/Account.jsx';
import FetchSchedules from './components/FetchSchedules/FetchSchedules.jsx';


function App() {
  const [session, setSession] = useState(null)
  const[users, setUsers] = useState(null)
  const [newUser, setNewUser] = useState([]);
  useEffect(() => {
  },[]) 
  async function checkID(){
    console.log("hello");
    try{
      const{data, error} = await supabase.from("profiles").select();
      if(error){  alert('Something went wrong')
      console.log(error)}
      if(data!=null){
          setUsers(JSON.stringify(data));
          const valuesArray = JSON.parse(users);
          valuesArray.map(items=> {
           if(session.user.id === items.id){
            setNewUser(true);
           }
          })
        }

  }
  catch(error){
      alert(error.message);
  }
  

  }
  
  async function signOut(){
    await supabase.auth.signOut();
  }

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])


  return (
  <div className="App">
<div className = "AppGlass">
    {/* if there isn't a session, go to sign-in page */}
    {/* <FetchSchedules forFetch = {{name: 'Keena Gao', id:'a2cd6c71-2de8-409a-8797-0525587dae2d', email:'keenagao@gmail.com', teamname:'TeamW'}}/> */}
      {!session ? <Auth /> :<Account key={session.user.id} session={session} />}
      </div>
    </div>
  )
}

export default App
