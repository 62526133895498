import React, { useEffect, useState } from 'react'
import './TradeBar.css'
import { supabase } from '../../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

function TradeBar(props) {
  const myName = props.name;
  const [savedTrades, setSavedTrades] = useState(null);
  const [noTrades, setNoTrades] = useState(false);
  const [trader, setTrader] = useState(null);
  const [savedMyTrades, setSavedMyTrades] = useState(null);

  let trades = [];
  let myTrades = [];
  useEffect(() => {
    fetchTrades();
    seeMyTrades();
  }, [])
  async function fetchTrades() {
    //see trades where you are the receiver and they have not been actively approved or rejected
    try {
      const { data, error } = await supabase
        .from('Trades')
        .select()
        .eq('Receiver', myName)
        .eq('approved', 'FALSE')
        .eq('Rejected', 'FALSE');
      if (error) {
        console.log(error);
        alert('Something went wrong')
      }
      if (data != null) {
        if (data.length === 0) {
          setNoTrades(true);
        }
        data.forEach(trade => {
          // push trades into array, tstartOfShift, rstartOfShift are for the full sized shifts

          const newTrade = {
            tstartOfShift: trade.tstartOfShift,
            tendOfShift: trade.tendOfShift,
            rstartOfShift: trade.rstartOfShift,
            rendOfShift: trade.rendOfShift,
            trader_start: trade.trader_start,
            trader_end: trade.trader_end,
            trader: trade.Trader,
            receiver_start: trade.receiver_start,
            receiver_end: trade.receiver_end,
            color: trade.color,
            trader_email: trade.trader_email,
            receiver_email: trade.receiver_email,
          };
          // Check if the new trade conflicts with existing trades
          if (!trades.some((existingTrade) => tradesConflict(newTrade, existingTrade))) {
            trades.push(newTrade);
          }
        });
        setSavedTrades(trades);
      }
    }
    catch (error) {
      alert(error.message);
    }
  }

  async function approveTrade(select) {
    setTrader(select.trader);
    //approve trade as receiver
    try {
      const { error } = await supabase
        .from('Trades')
        .update({ approved: true })
        .select()
        .eq('Receiver', myName)
        .eq('Trader', select.trader)
        .eq('trader_start', select.tstart)
        .eq('trader_end', select.tend)
        .eq('receiver_start', select.rstart)
        .eq('receiver_end', select.rend)
      if (error) {
        alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
        console.log(error)
      }
      else {
        tradeInSchedule(select)
      }
    }
    catch (error) {
      alert(error.message);
    }

  }

  function tradesConflict(newTrade, existingTrade) {
    return (
      (newTrade.trader_start === existingTrade.trader_start &&
        newTrade.trader_end === existingTrade.trader_end) ||
      (newTrade.receiver_start === existingTrade.receiver_start &&
        newTrade.receiver_end === existingTrade.receiver_end)
      // Add more conditions if needed
    );
  }

  async function seeMyTrades() {
    coalesce()
    try {
      const { data, error } = await supabase
        .from('Trades')
        .select()
        .eq('Trader', myName)
      if (error) {
        console.log(error);
        alert('Something went wrong')
      }
      if (data != null) {
        // console.log(data);
        data.forEach(trade => {
          // push trades into array, tstartOfShift, rstartOfShift are for the full sized shifts

          const newTrade = {
            trader_start: trade.trader_start,
            trader_end: trade.trader_end,
            trader: trade.Trader,
            receiver: trade.Receiver,
            receiver_start: trade.receiver_start,
            receiver_end: trade.receiver_end,
            approved: trade.approved,
            rejected: trade.Rejected,
          };
          if (!myTrades.some((existingTrade) => tradesConflict(newTrade, existingTrade))) {
            myTrades.push(newTrade);
          }
        });

        setSavedMyTrades(myTrades);
      }
    }
    catch (error) {
      alert(error.message);
    }
  }

  async function coalesce() {
    //TODO: Get team, or coalesce all
    try {
      const { data, error } = await supabase
        .from('schedules')
        .select('startTimes, endTimes, name, color')
        // .eq('team_name', 'Tent W')
        .neq('name', 'Missing Member')
        .neq('name', 'Grace Period')
      // console.log(data.length)
      data.forEach((schedule) => {
        const starts = schedule.startTimes
        const ends = schedule.endTimes
        const beginLength = starts.length
        let broken = true
        while (broken){
          broken = false
          outer:
          for (let i = 0; i < starts.length; i++) {
            for (let j = 0; j < starts.length; j++){
              if(i != j){
                const endShift1 = new Date(ends[i])
                const beginShift2 = new Date(starts[j])
                if(endShift1.getTime() == beginShift2.getTime()){
                  starts.push(starts[i])
                  ends.push(ends[j])
                  // console.log(schedule.name)
                  // console.log(starts[i])
                  starts.splice(i, 1)
                  ends.splice(i, 1)
                  if (j > i){
                    j--
                  }
                  starts.splice(j, 1)
                  ends.splice(j, 1)
  
                  broken = true
      
                  break outer;
                }
              }
            }
          }
        }

        const endLength = starts.length
        
        if(starts.length != ends.length){
          throw Error("Coalescing Broken")
        }
        // console.log("After")
        // console.log(starts)
        // console.log(ends)   

        const safety = true
        if(safety && beginLength != endLength){
          pushCoalesced(schedule, starts, ends)
        }
        else{
          console.log("not pushing")
        }
      })
    }
    catch (error) {
      console.log(error)
      alert(error.message);
    }
  }

  async function pushCoalesced(schedule, starts, ends){
    try{
      const {error} = await supabase
        .from('schedules')
        .update({
          startTimes: starts,
          endTimes: ends
        })
        .select()
        .eq('name', schedule.name)
        .eq('color', schedule.color)
      if(error){
        alert("Something went wrong")
        console.log(error)
      }
    }
    catch (error) {
      console.log(error)
      alert(error.message);
    }
  }

  async function tradeInSchedule(select) {
    coalesce()
    let noShift = false;
    let forTrader = null;
    const color = select.color;
    let newtStartTimes = [];
    let newrStartTimes = [];
    let newtEndTimes = [];
    let newrEndTimes = [];


    const tradersendToSplit = {
      start: select.tstart,
      end: select.tend,
      startOfShift: select.tstartOfShift,
      endOfShift: select.tendOfShift
    }
    console.log('tradersendtosplit')
    const splitTrader = splitTrade(tradersendToSplit);

    const receiversendToSplit = {
      start: select.rstart,
      end: select.rend,
      startOfShift: select.rstartOfShift,
      endOfShift: select.rendOfShift
    }
    const splitReceiver = splitTrade(receiversendToSplit);
    try {
      const { data, error } = await supabase
        .from('schedules')
        .select('startTimes, endTimes')
        .eq('name', select.trader)
        .eq('color', color)
        .single()
      if (error) {
        alert('Something went wrong')
        console.log(error)
      }
      else {
        data.startTimes.map((element, index) => {
          // Use index to uniquely identify each element
          if (element === select.tstartOfShift) {
          } else {
            newtStartTimes.push(element);
          }
        });

        //same for endtimes
        data.endTimes.map((element, index) => {
          // Use index to uniquely identify each element
          if (element === select.tendOfShift) {
          } else {
            newtEndTimes.push(element);
          }
        });

        let existingShifts = []
        newtEndTimes.map((element, index) => {

          existingShifts.push(
            {
              start: newtStartTimes[index],
              end: element,
            }
          )
        });
        const receiversTradeToGive = [{
          start: select.rstart,
          end: select.rend,
        }]


        //combines trader's shifts before or after the selected trade slot and existing Shifts that are not the full-trade slot and receivers slot
        const combinedTimes = [...splitTrader, ...existingShifts, ...receiversTradeToGive]
        const forTradePush = {
          times: combinedTimes,
          name: select.trader,
          color: color,
        }
        forTrader = forTradePush;
        if (data.endTimes.length === newtEndTimes.length) {
          alert('The person who traded you this shift no longer has this shift in their schedule. Trade did not execute.')
          noShift = true;
        }
        //  else{
        //  
        //  }
      }
    }
    catch (error) {
      console.log(error)
      alert(error.message);
    }

    //repeated process for receiver
    try {
      const { data, error } = await supabase
        .from('schedules')
        .select('startTimes,endTimes')
        .eq('name', myName)
        .eq('color', color)
        .single()
      if (error) {
        alert('Something went wrong')
        console.log(error)
      }
      else {
        data.startTimes.map((element, index) => {
          // Use index to uniquely identify each element
          if (element === select.rstartOfShift) {
          } else {
            newrStartTimes.push(element);
          }
        });


        //same deletion for endtimes
        data.endTimes.map((element, index) => {
          // Use index to uniquely identify each element
          if (element === select.rendOfShift) {

          } else {
            newrEndTimes.push(element);
          }
        });


        let existingRShifts = []
        newrEndTimes.map((element, index) => {
          existingRShifts.push(
            {
              start: newrStartTimes[index],
              end: element,

            }
          )
        });
        const tradersTradeToGive = [{
          start: select.tstart,
          end: select.tend,
        }]


        //combines receiver's shifts before or after the selected trade slot and existing Shifts that are not the full-trade slot and receivers slot
        const combinedTimes2 = [...splitReceiver, ...existingRShifts, ...tradersTradeToGive]
        const forReceivePush = {
          times: combinedTimes2,
          color: color,
        }
        if (data.endTimes.length === newrEndTimes.length || noShift === true) {
          alert('You/another person no longer has this shift in their schedule. Trade did not execute.')
        }
        else {
          console.log('for trader', forTrader);
          pushNewTimesTrader(forTrader);
          pushNewTimesReceiver(forReceivePush);
        }
      }
    }
    catch (error) {
      console.log(error)
      alert(error.message);
    }

    coalesce()
  }

  async function pushNewTimesTrader(toPush) {
    const color = toPush.color;
    let traderStartTimes = [];
    let traderEndTimes = [];
    toPush.times.map((element, index) => {
      traderStartTimes.push(element.start);
      traderEndTimes.push(element.end);
    });
    try {
      const { error } = await supabase
        .from('schedules')
        .update({
          startTimes: traderStartTimes,
          endTimes: traderEndTimes
        }
        )
        .select()
        .eq('name', toPush.name)
        .eq('color', color)
      if (error) {
        alert('Something went wrong')
        console.log(error)
      }
      else {
        console.log('successfully updated')
      }
    }
    catch (error) {
      console.log(error)
      alert(error.message);
    }

  }

  async function pushNewTimesReceiver(forReceivePush) {
    const color = forReceivePush.color
    const toPush = forReceivePush.times
    let traderStartTimes = [];
    let traderEndTimes = [];
    toPush.map((element, index) => {
      traderStartTimes.push(element.start);
      traderEndTimes.push(element.end);
    });
    try {
      const { error } = await supabase
        .from('schedules')
        .update({
          startTimes: traderStartTimes,
          endTimes: traderEndTimes
        }
        )
        .select()
        .eq('name', myName)
        .eq('color', color)
      if (error) {
        alert('Something went wrong')
        console.log(error)
      }
      else {
        console.log('successfully updated')
      }
    }
    catch (error) {
      console.log(error)
      alert(error.message);
    }

  }

  //splits original shift into before and after the trade shift
  function splitTrade(props) {
    let parts = [];
    if (props.start > props.startOfShift) {
      parts.push({ start: props.startOfShift, end: props.start })
    }
    if (props.end < props.endOfShift) {
      parts.push({ start: props.end, end: props.endOfShift })
    }
    return parts;

  }

  async function denyTrade(select) {
    //approve trade as receiver
    try {
      const { error } = await supabase
        .from('Trades')
        .update({ Rejected: true })
        .select()
        .eq('Receiver', myName)
        .eq('trader_start', select.tstart)
        .eq('trader_end', select.tend)
        .eq('receiver_start', select.rstart)
        .eq('receiver_end', select.rend)


      if (error) {
        alert('Something went wrong')
        console.log(error)
      }
    }
    catch (error) {
      alert(error.message);
    }
  }


  const handleApprove = (trade) => {
    // <EmailTrade/>
    // Logic to handle approval of trade request
    alert("You have approved the request. Please refresh the page to continue.")
    const selectedTrade = {
      tstart: trade.trader_start,
      tend: trade.trader_end,
      trader: trade.trader,
      rstart: trade.receiver_start,
      rend: trade.receiver_end,
      tendOfShift: trade.tendOfShift,
      tstartOfShift: trade.tstartOfShift,
      rendOfShift: trade.rendOfShift,
      rstartOfShift: trade.rstartOfShift,
      color: trade.color,
      trader_email: trade.trader_email,
      receiver_email: trade.receiver_email,
    }
    approveTrade(selectedTrade);
  };

  const handleReject = (trade) => {
    // Logic to handle rejection
    alert("You have rejected the request. Please refresh the page to continue.")
    const selectedTrade = {
      tstart: trade.trader_start,
      tend: trade.trader_end,
      trader: trade.trader,
      rstart: trade.receiver_start,
      rend: trade.receiver_end,
    }
    denyTrade(selectedTrade);
  };

  // const sendEmail = (receiver) => {
  //   emailjs.send(serviceID, templateID, {
  //     starttime: starts,
  //     endtime: ends,
  //     name: name,
  //     //set person
  //     to_name: selectedPerson,
  //     message:message,
  //     email_to:receiver,
  //     email_from:myEmail
  //   }, userID)
  //     .then((response) => {
  //       console.log('Email sent successfully:', response);
  //      setSubmittedTrade(true);
  //     })
  //     .catch((error) => {
  //       alert('Error sending email')
  //       console.error('Error sending email:', error);
  //     });
  //     setSubmittedTrade(true);
  // };

  
  return (
    <div className="allTradeBar">
      {savedTrades && (
        <div className="TradeBar">
          <h1>Trades</h1>
          {noTrades && <p class="main-p">You currently have no trade requests. </p>}
          <p class="main-p">To send a trade request, click on the shift you would like to trade away in "Week" view. If your team has red unfilled shifts, click on a shift to add it to your schedule.</p>
          {savedTrades.map((trade, index) => (
            <div key={index} className="trade-item">
              <div className="oval-card">
                <h2>{trade.trader}</h2>
                <p class="card-p">Their Shift: {trade.trader_start} to {trade.trader_end}</p>
                <p class="card-p">Your Shift: {trade.receiver_start} to {trade.receiver_end}</p>
                <div className="responsebuttons">
                  <div className="approve">
                    <FontAwesomeIcon icon={faCheck} size="xl" onClick={() => handleApprove(trade)} />
                  </div>
                  <div className="approve">
                    <FontAwesomeIcon icon={faXmark} size="xl" onClick={() => handleReject(trade)} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* see my trades */}
          {savedMyTrades && (<>
            {/* {console.log(savedMyTrades)} */}
            {savedMyTrades.slice(-3).map((trade, index) => (
              <div key={index} className="trade-item">
                <div className="mytrade">
                  {trade.rejected === false && trade.approved === false && (
                    <p className="small-p">You sent a trade to {trade.receiver}. Your Status: Pending Approval</p>
                  )}
                  {trade.approved === true && (
                    <p className="small-p">You sent a trade to {trade.receiver}. Your Status: Approved</p>
                  )}
                  {trade.rejected === true && (
                    <p className="small-p">You sent a trade to {trade.receiver}. Your Status: Rejected</p>
                  )}
                  <p class="small-p">Your Shift: {trade.trader_start} to {trade.trader_end}</p>
                  <p class="small-p">Their Shift: {trade.receiver_start} to {trade.receiver_end}</p>

                </div>
              </div>
            ))}
          </>
          )}
        </div>
      )}
    </div>
  );
}

export default TradeBar
