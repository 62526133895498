import React, { useRef, useState, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { supabase } from '../../supabaseClient';
import './EmailForm.css';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CircularProgress from '@mui/material/CircularProgress';

export const EmailForm = (select) => {
  const myEmail = select.tradeshift.email;
  const tradeshift = select.tradeshift.selectEvent;
  const myName = tradeshift.event.currentid;
  const color = tradeshift.event.title;
  const teamName = tradeshift.teamname;
    const[trades,setTrades] = useState(null);
    const[clickedTrade, setClickedTrade] = useState(null);
    const[selectedPerson, setSelectedPerson] = useState('');
    const[selectedShift, setSelectedShift ] =useState(null);
    const[listNamesDisplay, setListNamesDisplay] = useState(null);
    const[listShiftsDisplay, setListShiftsDisplay] = useState(null);
    const[suggestionsForTrade, setSuggestionsForTrade] = useState(null);
    let names = [];
    let availableShifts = [];
        //see if already submitted message/email
        const[submittedTrade, setSubmittedTrade] = useState(false);
        //data for the message from myschedule
        const [starts,setStarts]= useState(null);
        const [ends, setEnds]= useState(null);
        const[name, setName] = useState(null);
        const[message, setMessage] = useState(null);
        const[selectedReceiver, setSelectedReceiver] = useState(null);
        const form = useRef();
        //this is for the value of the picker for the trade
        const [value, setValue] = useState(dayjs(new Date(tradeshift.event.start)));
        const[endValue, setEndValue] = useState(dayjs(new Date(tradeshift.event.end)));
        const startOfShift = new Date((tradeshift.event.start));
        const endOfShift = new Date((tradeshift.event.end));
        useEffect(() => {
          const startTimeString = tradeshift.event.start.toString().substring(0,21);
          const endTimeString = tradeshift.event.end.toString().substring(0,21);
        //for my shift to trade
        setStarts(startTimeString);
        setEnds(endTimeString);
        setName(myName);
        setSuggestionsForTrade(false);
      }, [])
        const serviceID= process.env.REACT_APP_EMAIL_FORM_SERVICEID;
        const templateID = process.env.REACT_APP_EMAIL_FORM_TEMPLATEID;
        const userID = process.env.REACT_APP_EMAIL_FORM_USERID;
    
      const handleChange = (event) => {
        setSelectedPerson(listNamesDisplay[event.target.value]);
        // setSelectedPerson(listNamesDisplay[event.target.value]);
      };
  
      //getting availabilities from supabase
      const fetchAvailabilityData = async() =>{
        try{
          const{data, error} = await supabase.from("Availability").select().eq('team_name', teamName);
          if(error) {
            alert('Something went wrong')
            console.log(error);}
          if(data!=null){
              fetchShiftData(data);
          }
      }
      catch(error){
          alert(error.message);
      }
      }

      const fetchShiftData = async(availability) =>{
        try{
          const{data, error} = await supabase.from("schedules").select()
          .eq('team_name', teamName)
          .eq('notForTrade', false)
          .eq('color', color);
          if(error) {
            alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
            console.log(error)
          };
          if(data!=null){
              fetchData({availability:availability,
              schedule: data})

          }
      }
      catch(error){
          console.log(error);
      }
      }

      const fetchData = async (props) => {
        const supabaseAvailability = props.availability;
        let unav_obj = []
        supabaseAvailability.map((item, index) => {
          const unav_obj2 = { key: item.id,
          startTimes: item.startTimes,
          endTimes: item.endTimes,
          
        }
        unav_obj.push(unav_obj2);
      })
      let schedule_obj = []
      props.schedule.map((item, index) => {
        const sched_obj2 = { key: item.name,
        startTimes: item.startTimes,
        endTimes: item.endTimes,
      }
      schedule_obj.push(sched_obj2);
    })
        const tenting_type = color;
        const member_name = myName;
        
        const shift_obj = {startTime: new Date(value).toString().substring(0,21), endTime: new Date(endValue).toString().substring(0,21)};
        try {
          const response = await fetch('https://when2tent.pythonanywhere.com/suggesttrade', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({unav_obj,tenting_type,member_name, shift_obj, schedule_obj }),
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
  
          const data = await response.json();
          if(!data.errorMessage){
          
          setTrades(data.tradeSuggestions);
          Object.entries(data.tradeSuggestions).map(([person, personSchedules]) => {
            names.push(person);
            personSchedules.map((schedule, index) => {
              availableShifts.push({
                name: person,
                startschedule: schedule.substring(0,21),
                endschedule: schedule.substring(25,46),
                startOfShift: schedule.substring(60,81),
                endOfShift:schedule.substring(85,106),
                score:parseInt(schedule.substring(115,116))
              });
            });
          })
          setListNamesDisplay(names);
          setListShiftsDisplay(availableShifts);
          setSuggestionsForTrade(true);
          }
          else{
              alert(data.errorMessage)    
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  //for picking the shift
    const handlePick = (event) =>{
      listShiftsDisplay
      .filter((shift) => shift.name === selectedPerson)
      .map((shift, index) => {
        if (index === event.target.value) {

          setSelectedShift({
            start: shift.startschedule,
            end:shift.endschedule,
            startOfShift:shift.startOfShift,
            endOfShift:shift.endOfShift}
          )
        }      
      });
    
    // listShiftsDisplay
    //   .filter((shift) => shift.name === selectedPerson)
    //   .map((shift, index) => {
    //     if (index === event.target.value) {
    //       setSelectedShift({start: shift.startschedule,
    //         end: shift.endschedule, startOfShift:shift.startOfShift, endOfShift:shift.endOfShift
    //       });
    //     }      
    //   });
    }
  const sendEmail = (receiver) => {
    emailjs.send(serviceID, templateID, {
      starttime: starts,
      endtime: ends,
      name: name,
      //set person
      to_name: selectedPerson,
      message:message,
      email_to:receiver,
      email_from:myEmail
    }, userID)
      .then((response) => {
        console.log('Email sent successfully:', response);
       setSubmittedTrade(true);
      })
      .catch((error) => {
        alert('Error sending email')
        console.error('Error sending email:', error);
      });
      setSubmittedTrade(true);
  };
    //getting receiver's email
    const fetchReceiverEmail = async(e) =>{
      e.preventDefault();
      try{
        const{data, error} = await supabase.from("profiles").select().eq('full_name', selectedPerson).single();
        if(error) {
          if(error.code === 'PGRST116'){
            alert('That person does not exist in the database.')
          }
          else{
          alert(error.message)
          }

          console.log(error);}
          else{
        if(data!=null){
            sendEmail(data.email);
            console.log(selectedShift);
            pushTrades(data.email);
        }
      }
    }
    catch(error){
        alert(error.message);
    }
    }
    //send trades to supabase
    async function pushTrades(receiverEmail) {
      const tradestartstring = new Date(value).toString().substring(0,21);
      const tradeendstring = new Date(endValue).toString().substring(0,21);
      console.log(selectedShift);
      const receiverstartstring = selectedShift.start;
      const receiverendstring = selectedShift.end;
    try {
      const { error:insertError } = await supabase
    
    .from('Trades')
    .insert({  Trader: name,
    trader_start: tradestartstring,
    trader_end: tradeendstring,
    Receiver: selectedPerson,
    receiver_start: receiverstartstring,
    receiver_end: receiverendstring ,
  tstartOfShift:starts,
tendOfShift: ends,
color:color,
trader_email: myEmail,
receiver_email:receiverEmail,
//wrong
rstartOfShift: selectedShift.startOfShift,
rendOfShift: selectedShift.endOfShift, })
    if (insertError) {
    console.error('Error inserting event into Supabase:', insertError);
    }
    
    } catch (error) {
      console.error('Error inserting event into Supabase:', error);
    }
    
    }
    function handlePickStart(props){
      const selectedStart = new Date(props);
      const illegal = selectedStart && endOfShift && dayjs(endOfShift).isBefore(dayjs(selectedStart), 'day')||dayjs(selectedStart).isBefore(dayjs(startOfShift))
      illegal?<>{alert('Pick a start time for your trade that is before the end of this shift or return to your schedule to pick a different shift to trade. The default value has been set to the start of your shift.')}
      {setValue(startOfShift)}</> 
      :
      <>
      {setValue(props)}
      </>
    }
    function handlePickEnd(props){
      const selectedEnd = new Date(props);
      const illegal = selectedEnd && startOfShift && dayjs(selectedEnd).isBefore(dayjs(startOfShift), 'day')||dayjs(endOfShift).isBefore(dayjs(selectedEnd));
      // const illegal = dayjs(selectedEnd).isBefore(dayjs(startOfShift));
      illegal?<>{alert('Pick a end time for your trade that is within the range of this shift or return to your schedule to pick a different shift to trade. The default value has been set to the end of your shift.')}
      {setEndValue(endOfShift)}</> 
      :
      <>
      {setEndValue(props)}
      </>
    }
    
    function handleTradeSuggest(){
      setClickedTrade(true);
      //begins trade suggestion
      fetchAvailabilityData();
    }

  return (
    <div className ="trades">
       <div className = "tradeinfo">
        <div>
        Trade {starts} to {ends} with {selectedPerson}
        </div>
        <div className = "timepickers">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
      <DemoItem label="Start of Trade">
          <DateTimePicker 
          required
          // disableIgnoringDatePartForTimeValidation
          defaultValue={dayjs(new Date(tradeshift.event.start))} 
          value={value}
          onChange={(newValue) => handlePickStart(newValue)}
          skipDisabled = {true}
          minutesStep= {30}
           />
        </DemoItem>
        
        <DemoItem label="End of Trade">
          <DateTimePicker 
          required
          disableIgnoringDatePartForTimeValidation
          defaultValue={dayjs(new Date(tradeshift.event.end))} 
          value={endValue}
          onChange={(newValue) => handlePickEnd(newValue)}
          skipDisabled = {true}
          minutesStep= {30}
           />
        </DemoItem>

        {/* if trade suggestions clicked */}
        {clickedTrade? <>
        {suggestionsForTrade?<>
          {trades&&
          <div>
    {/* Drop down menu to select member to trade with */}
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Member</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedPerson||''}
          label='Person'
          onChange={handleChange}
        >
          {trades && listNamesDisplay.map((person, index)=>(
            <MenuItem value={index}>{person}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>

    <div className = "shiftList">
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Shift</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedShift}
          label="age"
          onChange={handlePick}
        >

            {listShiftsDisplay.filter((shift) => shift.name === selectedPerson)
              .map((shift, index) => (
                
                <MenuItem value={index}
              //  style={{color: shift.score === 0 ?'green':
              //   shift.score ===1? 'yellow':
              //   shift.score ===2? 'red': 'blue'
              //  }
              //   }
                >{shift.startschedule} to {shift.endschedule}</MenuItem>
                
              ))}
        </Select>
      </FormControl>
    </Box>
           </div>
      </div>}
        </>
          
          :
          <><Box sx={{ display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
        </>}
        
      </>
        :
         <button className = "tradeSuggest" onClick = {() =>handleTradeSuggest()}>See Suggestions for Trades </button>}
        {submittedTrade? 
        <div> 
            Thanks for submitting a trade to {selectedPerson}! 
            No changes will be made to your schedule until they accept the request. 
            Feel free to text them to check their trades if it is an urgent request. Click exit to return to your schedule.
        </div>
        : 
        <div>
        <form ref={form} onSubmit={fetchReceiverEmail}> 
        <label>Message</label>
        <textarea name="message" onChange={(e) => setMessage(e.target.value)} />
        <input type="submit" value="Send"/>
      </form>
      </div>}

        </DemoContainer>
    </LocalizationProvider>
    </div>
        </div>
    </div>
  );
};