import React, {useState, useEffect} from 'react'
import { supabase } from '../../supabaseClient.js'
import './InputForm.css'
import WeekInput from '../../WeekInput/WeekInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FetchSchedules from '../FetchSchedules/FetchSchedules.jsx';
 function InputForm({session}) {
    const [full_name, setFullName] = useState(null)
    const [team_name, setTeam_name] = useState(null)
    const[team_members, setTeam_members] = useState(null)
    const [loading, setLoading] = useState(true);
    const[formError, setFormError] = useState(null);
    const[team_pass,setTeam_pass]= useState(null);
    const[next, setNext] = useState(false);
    // const handleChange = (event) => {
    //   setColor(event.target.value);
    // };

    useEffect(() => {
        let ignore = false
        setLoading(false)
        return () => {
          ignore = true
        }
      }, [session])


       //function for handling push of data

       async function insertProfile(event) {
        event.preventDefault()
    
        setLoading(true)
        const { user } = session
        const updates = {
          id: user.id,
          full_name,
          team_name,
          email:session.user.email
        }
    
        const { error } = await supabase.from('profiles').upsert(updates)
      
    
        if (error) {
          alert('Something went wrong')
          console.log(error);
        } 
        
        const{error:rosterError} = await supabase.from('Roster').insert({
          team_name: team_name,
          team_lead: user.email,
          members_registered : 1,
          total_members: team_members,
          team_pass:team_pass,
        }
        )
        if (rosterError) {
          if(rosterError.code == '23505'){
            alert("This team name already exists")
          }
          else{
            console.log(rosterError);
            alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
          }
          
        } 
        else{
          console.log("success for roster")
          setNext(true)
          setLoading(false)
        }
       
      }
    
 function handleInformation(){
  alert("You cannot change the number of team members for your team once it's set. ")
 }
 function handlePassInformation(){
  alert("Please make an easy to remember password and share it with the rest of the members on your team. ")
 }

  return (
    <div>
        {!next&&
        <>
      {formError && <p className = "error"> {formError}</p>}
      <form  onSubmit={insertProfile} className="form-widget"> 
    <div>
      <label htmlFor="username">Full Name(First and Last)</label>
      <input
        id="username"
        className = "username"
        type="text"
        required
        value={full_name || ''}
        onChange={(e) => setFullName(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="team_name">Team Name</label>
      <input
        id="team_name"
        type="text"
        required
        value={team_name || ''}
        onChange={(e) => setTeam_name(e.target.value)}
      />
    </div>
    {/* <div className = "dropdownmenu">
    <Box sx={{ minWidth: 120 }}>
      <FormControl required fullWidth>
        <InputLabel id="demo-simple-select-label">Color</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={color}
          label="Color *"
          onChange={handleChange}
        >
          <MenuItem value={'Black'}>Black</MenuItem>
          <MenuItem value={'Blue'}>Blue</MenuItem>
          <MenuItem value={'White'}>White</MenuItem>
        </Select>
      </FormControl>
    </Box>
    </div> */}

    <div>
      <label htmlFor="team_members">Total Number of Team Members </label>
      <FontAwesomeIcon icon={faCircleInfo} onClick ={handleInformation} />
      <input
        id="team_members"
        type="integer"
        required
        value={team_members || ''}
        onChange={(e) => setTeam_members(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="teampass">Password for Team </label>
      <FontAwesomeIcon icon={faCircleInfo} onClick ={handlePassInformation} />
      <input
        id="team_pass"
        type="text"
        required
        value={team_pass || ''}
        onChange={(e) => setTeam_pass(e.target.value)}
      />
    </div>
    <button className="button block primary" type="submit" disabled={loading}>
          {loading ? 'Loading ...' : 'Submit'}
        </button>
    </form>
    </>
 }



    {
    next && <WeekInput toWeek = {{
      id: session.user.id,
      email:session.user.email,
      name: full_name,
      teamname:team_name,
    }}/>}
    </div>

  )
}

export default InputForm