import React from 'react'
import { useState, useEffect } from 'react'
import WeekInput from '../../WeekInput/WeekInput'
import './JoinTeamForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../../supabaseClient';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// Everytime someone joins a team increase members registered by 1, check members registered against total members, change complete to true if = 
// if complete is true, don't let people join team
// send email to team lead when complete

function JoinTeamForm({session}) {
    const[submit, setSubmit] = useState();
    const[buttonClicked, setButtonClicked] = useState(false);
    const[username, setUserName] = useState("");
    const[password, setPassword] = useState("");
    const[teams, setTeams] = useState(null);
    const[selectedTeam, setSelectedTeam] = useState(null);
    const[correctPassword, setCorrectPassword] = useState(false);
    const handleChange = (e) =>{
        setUserName(e.target.value);
    };
    const handlePass = (e) =>{
      setPassword(e.target.value);
  };
  const handleTeam = (e) =>{
    setSelectedTeam(e.target.value);
};
const handleSubmit = (e) => {
  e.preventDefault();

  const teamWithCorrectPassword = teams.find((team) => team.team_name === selectedTeam && team.team_pass === password);

  if (teamWithCorrectPassword) {
    joinTeam();
    addToProfile();
    setCorrectPassword(true);
    nextStep()
  } else {
    // Handle incorrect password or team not found
    alert("That is the wrong password for your team. Please contact whoever in your team registered your team.")
  }
};

    const nextStep = () =>{
      setSubmit(true);
      setButtonClicked(true);
    };
    function handleInformation(){
      alert("Ask your team leader(the person who registered your team on When2Tent) for the password.")
    }

    useEffect(() => {
      fetchTeams();
      }, []);

      async function addToProfile(){
        try{
          const { data,error } = await supabase
          .from('profiles')
          .upsert({
            id: session.user.id,
            email:session.user.email,
            full_name: username,
            team_name: selectedTeam,
          })
          if(error){
            console.log(error);
            alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.');
          }
      }
      catch(error){
        console.log(error);
      }
    }
    async function joinTeam(){
      try{
        const { data,error } = await supabase
        .from('Roster')
        .select('members_registered')
        .eq('team_name', selectedTeam)
        .single()
        if(error) {
          console.log(error);
          alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.');
        }
        if(data!=null){
          addToRoster(data.members_registered+1);
        }
    }
    
    catch(error){
        alert(error.message);
    }
  }

  async function addToRoster(props){
    //show all teams registered
    try{
      const { data:InsertData,error:InsertError } = await supabase
      .from('Roster')
      .update({members_registered: props})
      .eq('team_name', selectedTeam)
      if(InsertError) throw InsertError;
  }
  
  catch(InsertError){
    alert('Something went wrong')
      console.log(InsertError);
  }
}

    async function fetchTeams(){
      //show all teams registered
      try{
        const { data,error } = await supabase
        .from('Roster')
        .select('team_name, team_pass, members_registered, total_members')
        if(error) {
          alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
          console.log(error);
        }
        if(data!=null){
          const filteredTeams = data.filter((team) => team.members_registered != team.total_members);
          //data is an array of team_name attributes and passwords
          setTeams(filteredTeams);
        }
    }
    
    catch(error){
        console.log(error);
    }
  }
  



  return (
        <div className >
    {!buttonClicked?<div className = "parent">
    <div className = "header">
        <div className = "describe">Join an existing team 
        <FontAwesomeIcon icon={faCircleInfo} onClick ={handleInformation} />
        </div>
    </div>
    <div className = "inputform">
      <form onSubmit = {handleSubmit}>
      <FormControl required fullWidth>
    <TextField id="outlined-basic"  label="Full Name" variant="outlined" onChange = {handleChange}/>
    </FormControl>
      <div className = "spacing">
        </div>
      {teams && <div ><Box sx={{ minWidth: 120 }}>
      <FormControl required fullWidth>
        <InputLabel id="demo-simple-select-label">Team Name</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="team"
          value = {selectedTeam}
          onChange={handleTeam}
        >
          {teams.map((team, index)=>(
            <MenuItem value={team.team_name}>{team.team_name}</MenuItem>
          ))}
        </Select>
      </FormControl>
        </Box></div>}
        <div className = "spacing">

        </div>
        <FormControl required fullWidth>
      <TextField id="outlined-basic" value = {password} label="Team Password" variant="outlined" onChange = {handlePass} />
      </FormControl>
      <div className = "spacing">
</div>  
<Stack spacing={2} direction="row">

      <Button variant="outlined" type = "submit">Submit</Button>
    </Stack>
        {/* <button type = "submit" >Submit</button> */}
      </form>
    </div>
     </div>
     :
     <><WeekInput toWeek = {{
      id: session.user.id,
      email:session.user.email,
      name: username,
      teamname:selectedTeam,
    }}/></>}
    </div>
  )
}


export default JoinTeamForm