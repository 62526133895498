import React, { useEffect, useState }from 'react';
import MySchedule from '../../React Big Calendar/MySchedule';
import { supabase } from '../../supabaseClient';
import WaitingOnPeople from '../WaitingOnPeople/WaitingOnPeople';
import emailjs from '@emailjs/browser';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons';

function FetchSchedules(pass) {
  const REACT_APP_SERVICEID = process.env.EMAIL_FORM_SERVICEID;
  const REACT_TEMPLATEID= process.env.EMAIL_FORM_TEMPLATEID;
  const REACT_PUBLICKEY = process.env.EMAIL_FORM_USERID;
  const[teamName, setTeamName] = useState(null);
  const[data, setData] = useState({});
  const[teamLead, setTeamLead] = useState(null);
  const[notEnoughAvailability, setNotEnoughAvailability] = useState(false);
  const[loading,setLoading ] =useState(true);
  const[fetching, setFetching] = useState(false);
  const[pushed, setPushed] = useState(false);
   const events = [];
   const [count, setCount] = useState(0);
  const id = pass.forFetch.id;
  const name = pass.forFetch.name;
  const email = pass.forFetch.email;
  const teamname = pass.forFetch.teamname;
   const splitDate = (dateString) => {
    const [startDate, endDate] = dateString.split(' to ');
    
    
    return { startTime: startDate, endTime: endDate };
  };
  const[completeTeam, setCompleteTeam] = useState(false);
  const[remainingPeople, setRemainingPeople] = useState(null);
        //RUNS ONCE
useEffect(() => {
  fetchTeam();
  }, []);

  const fetchNewSchedule = async (supabaseAvailability) => {
    let team_name = ''
    let unav_obj = []
    let complete = []
    supabaseAvailability.map((item, index) => {
      const unav_obj2 = { key: item.id,
      startTimes: item.startTimes,
      endTimes: item.endTimes,
    }
    team_name = item.team_name;
    unav_obj.push(unav_obj2);
    // console.log(unav_obj2)
  })
 
    try {
      // http://127.0.0.1:5000
      const response = await fetch('https://when2tent.pythonanywhere.com/newschedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({unav_obj }),
      });
  
      if (!response.ok) {
        // Handle non-OK response
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      } 
  
      const data = await response.json();


      if (data!=null) {
        if(data.errorMessage){
          // console.log(data.errorMessage);
          // console.log(data.finalSchedule)
          // console.log(data.finalSchedule);
          // console.log(data.finalSchedule.Blue)
          // alert(data.errorMessage);
        }
        pushSchedule({data:data,
          team_name: team_name});
        
      } else {

          // console.log(data);
          alert(data.errorMessage);
      
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  //push new fetched schedules to supabase
const  pushSchedule = async (props)=>{
    const data = props.data;
    const team_name = props.team_name;
    let packagedBlack = [];
    let packagedBlue = [];
    let packagedWhite = [];
    Object.entries(data.finalSchedule.Black).map(([name, values]) => {
      values.map((times, index) => {
        let starts = []
        let ends = [];
        let notForTrade = false;
        if(name === ('Missing Member')|| name ===('Grace Period')){
          notForTrade = true;
        }
          const formattedTime = splitDate(times);
          starts.push(formattedTime.startTime);
          ends.push(formattedTime.endTime);
          packagedBlack.push({
            name: name,
            start:starts,
            end:ends,
            notForTrade:notForTrade,
          });
      });
        })
        //Blue
        Object.entries(data.finalSchedule.Blue).map(([name, values]) => {
      
          values.map((times, index) => {
            let notForTrade = false;
        if(name === ('Missing Member')|| name ===('Grace Period')){
          notForTrade = true;
        }
            let starts = []
            let ends = [];
              const formattedTime = splitDate(times);
              starts.push(formattedTime.startTime);
              ends.push(formattedTime.endTime);
              packagedBlue.push({
                name: name,
                start:starts,
                end:ends,
                notForTrade:notForTrade,
              });
          });
            })


        //White

        Object.entries(data.finalSchedule.White).map(([name, values]) => {
      
          values.map((times, index) => {
            let starts = []
            let ends = [];
            let notForTrade = false;
        if(name === ('Missing Member')|| name ===('Grace Period')){
          notForTrade = true;
        }
              const formattedTime = splitDate(times);
              starts.push(formattedTime.startTime);
              ends.push(formattedTime.endTime);
              packagedWhite.push({
                name: name,
                start:starts,
                end:ends,
                notForTrade:notForTrade,
              });
          });
            })
          data.roster_keys.forEach((person) => {
          let starttimes = [];
          let endtimes = [];
          let notForTrade = true;
          packagedBlack.forEach((item) => {
          if(item.name === person){
              starttimes.push(item.start);
              endtimes.push(item.end);
              notForTrade=item.notForTrade;
          }
        })
        const pushBlack = {
          name:person,
          startTimes: starttimes.flat(),
          endTimes: endtimes.flat(),
          color: 'Black', 
          team_name: team_name,
          notForTrade:notForTrade,
        }
        pushForPerson(pushBlack)

        //  NOW FOR BLUE 
        let starttimesblue = [];
        let endtimesblue = [];
        packagedBlue.forEach((item) => {
          
        if(item.name === person){
            starttimesblue.push(item.start);
            endtimesblue.push(item.end);
            notForTrade=item.notForTrade;
        }
      })
      const pushBlue = {
        name:person,
        startTimes: starttimesblue.flat(),
        endTimes: endtimesblue.flat(),
        color: 'Blue',
        team_name: team_name,
        notForTrade:notForTrade,
      }
      pushForPerson(pushBlue)

     //NOW FOR WHITE
     let starttimeswhite = [];
        let endtimeswhite = [];
        packagedWhite.forEach((item) => {
        if(item.name === person){
            starttimeswhite.push(item.start);
            endtimeswhite.push(item.end);
            notForTrade=item.notForTrade;
        }
      })
      const pushWhite = {
        name:person,
        startTimes: starttimeswhite.flat(),
        endTimes: endtimeswhite.flat(),
        color: 'White',
        team_name: team_name,
        notForTrade:notForTrade,
      }
      pushForPerson(pushWhite)
     })
  }

  const pushForPerson = async (dataPush) => {
    const name = dataPush.name;
    const color = dataPush.color;
    const team_name = dataPush.team_name;
    try {
      // Check if an entry with the same color, person, and team_name already exists
      const { data: existingEntry, error:existingEntryError } = await supabase
        .from('schedules')
        .select()
        .eq('name', name)
        .eq('color', color)
        .eq('team_name', team_name)
        .single();
      
      if (existingEntry) {
        // Entry already exists, you can decide whether to update or skip based on your requirements
        console.log(`Entry already exists for ${name} with color ${color} and team ${team_name}`);
        setPushed(true);
        // For now, let's skip the insertion
      } 
      else {
        console.log(existingEntryError)
        // Entry doesn't exist, proceed with the insertion
        const { error } = await supabase
          .from('schedules')
          .upsert(dataPush);
  
        if (error) {
          console.log(error);
        }
        else {
          setLoading(false);
        }
      }

    } catch (error) {
      alert(error.message);
    }
  };
  
//   const  pushForPerson=async (dataPush) =>{


//     try{
//       const { error } = await supabase
//       .from('schedules')
//       .upsert(dataPush,{ignoreDuplicates: true})
//       if(error) throw error;
//       else{
//         setLoading(false);
//       }
//   }
  
//   catch(error){
//       alert(error.message);
//   }
 
  
// }


  async function fetchTeam(){
    //figure out which team to look at from profiles table, one return object
    try{
      const { data,error } = await supabase
      .from('profiles')
      .select()
      .eq('id', id).single();
      if(error) throw error;
      if(data!=null){
        setTeamName(data.team_name);
        fetchAvailable(data.team_name);
      }
  }
  
  catch(error){
      alert(error.message);
  }
}


  //this function compares the number of people to the total number of people registered from roster for a team and will fetch availability if there are twelve people's availability for a given team
async function fetchAvailable(teamname){
  //find number of people registered vs total from 'Roster' Table and check whether everyone's registered
  try{
   const { data: registeredCheck, error: queryError  } = await supabase
   .from('Roster')
   .select()
   .eq('team_name', teamname).single();
   if(queryError) {
    alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
    console.log(queryError);
  };
   if(registeredCheck!=null){
    setTeamLead(registeredCheck.team_lead)
    setRemainingPeople(registeredCheck.total_members - registeredCheck.members_registered)
     if (registeredCheck.total_members === registeredCheck.members_registered){
      // setTimeout(() => {
       setCompleteTeam(true);
      // },5000);
// setTimeout(() => {
  try{
    const{data, error} = await supabase.from("Availability").select().eq('team_name', teamname);
    if(error) {
      alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com. Please try signing out and signing back in or emailing contact@when2tent.com.')
      console.log(error)
    };
    if(data!=null){
      if(registeredCheck.total_members === data.length){
        console.log('fetching new')
        setFetching(true);
        fetchNewSchedule(data);
      }
      else{
       tryAvailableAgain(registeredCheck.total_members);
      }
    }
}
   // },5000);
catch(error){
    alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com.')
    console.log(error)

}
     }
     else{
       setCompleteTeam(false);
       setLoading(false);
     }
   }
 }
 catch(queryError){
   alert(queryError.message);
   console.log(queryError);
 }

 }  

 async function tryAvailableAgain(total){
  try{
    const{data, error} = await supabase.from("Availability").select().eq('team_name', teamname);
    if(error) {
      alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com.')
      console.log(error)
    };
    if(data!=null){
      if(total === data.length){
        console.log('fetching again')
        setFetching(true);
        fetchNewSchedule(data);
      }
      else{
       console.log('second time failed')
       tryThree(data.total_members)
      }
    }
}
   // },5000);
catch(error){
    alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com.')
    console.log(error)
}
 }

 async function tryThree(total){
  try{
    const{data, error} = await supabase.from("Availability").select().eq('team_name', teamname);
    if(error) {
      alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com.')
      console.log(error)
    };
    if(data!=null){
      if(total === data.length){
        console.log('fetching again')
        fetchNewSchedule(data);
        setFetching(true);
      }
      else{
       console.log('third time failed')
       alert('Your team has 12 members registered but not 12 availabilities in our database. Please email contact@when2tent.com to fix your database.')
      }
    }
}
   // },5000);
catch(error){
    alert('Something went wrong! Please try signing out and signing back in or emailing contact@when2tent.com.')
    console.log(error)

}
 }
 
  return (
    <div>
     {completeTeam? 
      <> 
      {/* {alert('Please refresh the screen in a few seconds to see your schedule.')} */}
      {fetching?<>
          {pushed?<>
            < MySchedule props={{
            id:id,
            name:name,
            email:email,
            teamname : teamname,
           }}/> 
          </>:
          <>
        <h1>Generating your schedule…   </h1>
        <FontAwesomeIcon icon={faFaceSmile} spin size="2xl" />
        <p>Do not refresh the page immediately. Please wait for at least 15 seconds.</p>
          </>}
       
      </>
      :
      <>
      <p>Calculating the schedule for your team! Please do not refresh</p>
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
        </Box>
      </>}
          
    
      </>
    :<>
    {remainingPeople&&  <WaitingOnPeople remainingPeople = {{remainingPeople:remainingPeople,name:name, teamname:teamname}}/> }
  
    </>}
    {/* </> */}
    {/* }  */}


  </div>
  );
}

export default FetchSchedules;



